import React, { memo, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setPaymentTotal } from '../redux/simulationSlice.js';
import { baseURL } from '../constants/routes.js';
import { useAuthContext } from './AuthProvider.js';
import { COST_PER_VISIT_IN_CENTS, FIXED_TRANSACTION_FEE_IN_CENTS, DEFAULT_CURRENCY, HARDCODED_CAD_TO_USD_RATE, VARIABLE_TRANSACTION_FEE_IN_PERCENT } from '../constants/payments.js';
import AuthForm from './AuthForm.js';
export function computeServiceFeeInCents(visitCount, paymentCurrency) {
    return visitCount * (paymentCurrency === 'USD' ?
        (HARDCODED_CAD_TO_USD_RATE * COST_PER_VISIT_IN_CENTS) :
        COST_PER_VISIT_IN_CENTS);
}
export function computeTransactionFeeInCents(paymentTotal, paymentCurrency) {
    const fixedFee = paymentCurrency === 'USD' ?
        HARDCODED_CAD_TO_USD_RATE * FIXED_TRANSACTION_FEE_IN_CENTS :
        FIXED_TRANSACTION_FEE_IN_CENTS;
    const variableFee = (VARIABLE_TRANSACTION_FEE_IN_PERCENT * paymentTotal);
    return fixedFee + variableFee;
}
;
async function initializeApplePay(payments, paymentRequest) {
    const applePay = await payments.applePay(paymentRequest);
    return applePay;
}
async function initializeGooglePay(payments, paymentRequest) {
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button');
    return googlePay;
}
function PaymentOptions() {
    const [isSdkReady, setSdkReady] = useState(false);
    const [isPaymentAvailable, setIsPaymentAvailable] = useState(true);
    const [paymentRequestBody, setPaymentRequestBody] = useState(null);
    const [applePayInitialized, setApplePayInitialized] = useState(false);
    const [googlePayInitialized, setGooglePayInitialized] = useState(false);
    const dispatch = useAppDispatch();
    const authContext = useAuthContext();
    const paymentCurrency = useAppSelector((state) => state.simulation.paymentCurrency) || DEFAULT_CURRENCY;
    const paymentTotal = useAppSelector((state) => state.simulation.paymentTotal);
    const visits = [1, 2, 3];
    // const visits: OptimizeToursRequestShipment[] | undefined =
    //     useAppSelector((state: RootState) => state.simulation.request?.optimizationRequest?.model.shipments);
    useEffect(() => {
        const loadSquareSdk = new Promise((resolve, reject) => {
            const checkVerificationLinkLoaded = () => {
                return document.querySelector('link[href="/.well-known/apple-developer-merchantid-domain-association"]');
            };
            if (window.Square && checkVerificationLinkLoaded()) {
                resolve(null);
            }
            else {
                const script = document.createElement('script');
                if (process.env.REACT_APP_ENV === 'DEV') {
                    script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
                }
                else {
                    script.src = 'https://web.squarecdn.com/v1/square.js';
                }
                script.onload = () => {
                    if (checkVerificationLinkLoaded()) {
                        resolve(null);
                    }
                    else {
                        reject(new Error('Verification link not found'));
                    }
                };
                script.onerror = reject;
                document.head.appendChild(script);
            }
        });
        loadSquareSdk.then(() => {
            console.log('Square SDK loaded');
            setSdkReady(true);
        }).catch((error) => {
            console.error('Failed to load the Square SDK:', error.message);
        });
    }, []);
    const buildPaymentRequest = useCallback(() => {
        if (!visits || !paymentCurrency || paymentRequestBody) {
            return;
        }
        const visitCount = visits.length;
        const serviceFeeInCents = computeServiceFeeInCents(visitCount, paymentCurrency);
        const transactionFeeInCents = computeTransactionFeeInCents(serviceFeeInCents, paymentCurrency);
        const totalFee = parseFloat(((serviceFeeInCents + transactionFeeInCents) / 100).toFixed(2));
        dispatch(setPaymentTotal({
            paymentCurrency,
            paymentTotal: totalFee,
        }));
        setPaymentRequestBody({
            countryCode: 'CA',
            currencyCode: paymentCurrency,
            total: {
                amount: totalFee.toString(),
                label: 'Minuteman -- Route Planning Service',
            },
        });
    }, [dispatch, paymentCurrency, paymentRequestBody, visits]);
    useEffect(() => {
        if (!isSdkReady || !visits || !authContext.currentUser ||
            !authContext.idToken || !authContext.currentUser.email) {
            return;
        }
        async function initializePayments() {
            let payments;
            try {
                payments = window.Square.payments(process.env.REACT_APP_SQUARE_APPLICATION_ID, process.env.REACT_APP_SQUARE_LOCATION_ID);
            }
            catch (e) {
                console.error(`Failed to initialize payments`, e);
            }
            if (!paymentRequestBody) {
                buildPaymentRequest();
                return;
            }
            async function initializePayment(paymentType, paymentRequestBody, payments, buttonId, initializeFunction, setInitialized) {
                const paymentButton = document.getElementById(buttonId);
                if (paymentButton) {
                    const paymentRequest = payments.paymentRequest(paymentRequestBody);
                    const paymentInstance = await initializeFunction(payments, paymentRequest);
                    setInitialized(true);
                    paymentButton.addEventListener('click', async function (event) {
                        setIsPaymentAvailable(false);
                        try {
                            if (paymentInstance) {
                                const tokenResult = await paymentInstance.tokenize();
                                if (tokenResult.status === 'OK') {
                                    if (!tokenResult.token) {
                                        throw new Error(`Successful tokenization but missing token from token result`);
                                    }
                                    console.log(`${paymentType} payment token is ${tokenResult.token}`);
                                    const response = await axios.post(`${baseURL}/payment`, {
                                        sourceId: tokenResult.token,
                                        paymentCurrency: paymentRequestBody.currencyCode,
                                        paymentTotal: parseFloat(paymentRequestBody.total.amount),
                                        email: authContext.currentUser.email,
                                        uid: authContext.currentUser.uid,
                                        idToken: authContext.idToken,
                                    }, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    });
                                    if (response.status !== 200) {
                                        console.error(`Failed to process payment`);
                                    }
                                    console.log(`Successfully processed payment! Payment ID ${response.data}`);
                                }
                                else {
                                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                                    if (tokenResult.errors) {
                                        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
                                    }
                                    console.error(errorMessage);
                                }
                            }
                        }
                        catch (e) {
                            console.error(`Failed during ${paymentType} initialization`, e);
                        }
                    });
                }
            }
            ;
            if (!googlePayInitialized) {
                await initializePayment('Google Pay', paymentRequestBody, payments, 'google-pay-button', initializeGooglePay, setGooglePayInitialized);
            }
            if (!applePayInitialized && process.env.REACT_APP_ENV === 'PROD') {
                await initializePayment('Apple Pay', paymentRequestBody, payments, 'apple-pay-button', initializeApplePay, setApplePayInitialized);
            }
        }
        initializePayments();
    }, [authContext, isSdkReady, applePayInitialized, googlePayInitialized, paymentRequestBody, buildPaymentRequest, visits]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { component: "form", id: "payment-form", sx: { padding: 2 } }, isPaymentAvailable && (React.createElement(React.Fragment, null,
            React.createElement("div", { id: "apple-pay-button", style: {
                    height: '48px',
                    width: '100%',
                    display: 'inline-block',
                    WebkitAppearance: '-apple-pay-button'
                } }),
            React.createElement("div", { id: "google-pay-button" }),
            React.createElement("div", { id: "card-container" }),
            React.createElement(Button, { variant: "contained", id: "card-button", type: "button", sx: { marginTop: 2 } }, `Pay ${paymentTotal} (${paymentCurrency})`)))),
        React.createElement(Box, { id: "payment-status-container", sx: { marginTop: 2 } }),
        React.createElement(Divider, null),
        React.createElement(AuthForm, null)));
}
const MemoizedPaymentOptions = memo(PaymentOptions);
export default MemoizedPaymentOptions;
