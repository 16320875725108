import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks.js';
import { useAuthContext } from './AuthProvider.js';
import populateWeatherStore from '../resources/populateWeatherStore.js';
export const RouteWeatherContext = createContext({});
export function RouteWeatherProvider({ visitCoords, children }) {
    const authContext = useAuthContext();
    // TODO: check if useMemo is required here
    // const visitCoords: LatLng[] = visits.map((loc) => loc.coords);
    const globalStartTimeISO8601 = useAppSelector((state) => state.simulation.result?.metrics.earliestVehicleStartTime) || '';
    const globalEndTimeISO8601 = useAppSelector((state) => state.simulation.result?.metrics.earliestVehicleStartTime) || '';
    const [weatherEventsResponse, setWeatherEventsResponse] = useState({});
    // TODO: Add in RouteGrid; modify RouteGrid to filter for visits
    // Visits are (1) reflected on the map and (2) passed to RouteWeatherProvider
    useEffect(() => {
        if (!authContext.currentUser)
            return;
        const weatherEventsRequest = {
            startTime: globalStartTimeISO8601,
            endTime: globalEndTimeISO8601,
            locations: visitCoords,
            snowDepthTrigger: 0,
        };
        if (process.env.REACT_APP_SHOW_WEATHER_GRAPH === "true") {
            populateWeatherStore(weatherEventsRequest, authContext.currentUser.uid, authContext.idToken).then((weatherEventsResponse) => {
                if (weatherEventsResponse) {
                    setWeatherEventsResponse(weatherEventsResponse);
                }
                ;
            });
        }
    }, [authContext, globalStartTimeISO8601, globalEndTimeISO8601, visitCoords]);
    return (React.createElement(RouteWeatherContext.Provider, { value: weatherEventsResponse }, children));
}
;
export function useRouteWeatherData() {
    return useContext(RouteWeatherContext);
}
;
