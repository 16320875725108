import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMapsLibrary, useMap } from '@vis.gl/react-google-maps';
import Grid from '@mui/material/Grid/Grid.js';
import { Button, MenuItem, Select, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.js';
import dayjs from "dayjs";
import DeviceDetect from './DeviceDetect.js';
import WeatherGraph from './WeatherGraph.js';
import { WeatherGraphProvider } from './WeatherGraphProvider.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setReferenceLocation, setRequest, setSnowDepthTrigger } from '../redux/simulationSlice.js';
import { validateLocation } from '../resources/validateSiteEligibility.js';
import { handleWeatherEventsResponse } from '../redux/locationSlice.js';
import { useAuthContext } from './AuthProvider.js';
import { setDistanceUnit, setLengthUnit, setTemperatureUnit } from '../redux/userSlice.js';
import populateWeatherStore from '../resources/populateWeatherStore.js';
export default function StepperGlobalTimeWindows({ handleNext, handleBack }) {
    const authContext = useAuthContext();
    const map = useMap();
    const placesLib = useMapsLibrary('places');
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    const EARLIEST_SIMULATION_START_DATE = dayjs().subtract(5, 'year').add(1, 'day');
    const LATEST_SIMULATION_START_DATE = dayjs().add(5, 'day');
    const textFieldRef = useRef(null);
    const [locationInput, setLocationInput] = useState('');
    const [centerCoords, setCenterCoords] = useState();
    const referenceLocation = useAppSelector((state) => state.simulation.referenceLocation);
    const simulationRequest = useAppSelector((state) => state.simulation.request);
    const snowDepth = useAppSelector((state) => state.simulation.snowDepthTrigger);
    const lengthUnit = useAppSelector((state) => state.user.lengthUnit);
    const [globalStartTime, setGlobalStartTime] = useState(null);
    const [globalEndTime, setGlobalEndTime] = useState(null);
    const [snowDepthInput, setSnowDepthInput] = useState(5);
    const [snowDepthUnitInput, setSnowDepthUnitInput] = useState('cm');
    const [shouldDisplayGraph, setShouldDisplayGraph] = useState(false);
    // Text validation slot props
    const [isInvalidLocation, setIsInvalidLocation] = useState(false);
    const [locationErrorMessage, setLocationErrorMessage] = useState('');
    const [isInvalidGlobalStartTime, setIsInvalidGlobalStartTime] = useState(false);
    const [isInvalidGlobalEndTime, setIsInvalidGlobalEndTime] = useState(false);
    const [isInvalidSnowDepth, setIsInvalidSnowDepth] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // Hook for creating new simulation if it doesn't exist already
        if (!simulationRequest || !Object.keys(simulationRequest).length) {
            const emptyModel = {
                shipments: [],
                vehicles: [],
                globalStartTime: '',
                globalEndTime: ''
            };
            const emptySimulationRequest = {
                optimizationRequest: {
                    parent: '',
                    timeout: '',
                    model: emptyModel,
                    injectedSolutionConstraint: {},
                }
            };
            dispatch(setRequest(emptySimulationRequest));
            dispatch(setSnowDepthTrigger(0));
        }
        else {
            // Re-hydrate step with existing simulation details
            if (simulationRequest.optimizationRequest?.model.globalStartTime &&
                simulationRequest.optimizationRequest?.model.globalEndTime) {
                setGlobalStartTime(dayjs(simulationRequest.optimizationRequest?.model.globalStartTime));
                setGlobalEndTime(dayjs(simulationRequest.optimizationRequest?.model.globalEndTime));
            }
            if (referenceLocation) {
                setLocationInput(referenceLocation.streetAddress);
                const serializedCoords = new window.google.maps.LatLng(referenceLocation.coords.lat, referenceLocation.coords.lng);
                setCenterCoords(serializedCoords);
            }
            if (snowDepth && lengthUnit) {
                if (lengthUnit === 'in') {
                    const adjustedSnowDepth = (Math.round((snowDepth * 0.39) * 2) / 2).toFixed(1);
                    setSnowDepthInput(parseFloat(adjustedSnowDepth));
                    setSnowDepthUnitInput('in');
                }
                else {
                    setSnowDepthInput(snowDepth);
                    setSnowDepthUnitInput('cm');
                }
            }
        }
    }, [dispatch, referenceLocation, snowDepth, lengthUnit, simulationRequest]);
    useEffect(() => {
        // Hook for populating weather in location store when autocomplete finishes.
        if (!centerCoords || !globalStartTime || !globalEndTime || !authContext || !authContext.currentUser) {
            return;
        }
        const weatherEventsRequest = {
            startTime: globalStartTime.toISOString(),
            endTime: globalEndTime.toISOString(),
            locations: [{ lat: parseFloat(centerCoords.lat().toFixed(2)), lng: parseFloat(centerCoords.lng().toFixed(2)) }],
            snowDepthTrigger: 0,
        };
        if (process.env.REACT_APP_SHOW_WEATHER_GRAPH === "true") {
            populateWeatherStore(weatherEventsRequest, authContext.currentUser.uid, authContext.idToken).then((weatherEventsResponse) => {
                if (weatherEventsResponse) {
                    dispatch(handleWeatherEventsResponse(weatherEventsResponse));
                    setShouldDisplayGraph(true);
                }
                ;
            });
        }
    }, [centerCoords, dispatch, globalStartTime, globalEndTime, authContext]);
    const handleTextFieldChange = useCallback((newTextFieldValue) => {
        setLocationInput(newTextFieldValue);
        if (!placesLib || !map || !textFieldRef.current)
            return;
        const autocompleteService = new placesLib.Autocomplete(textFieldRef.current, {
            bounds: map.getBounds(),
            fields: ["formatted_address", "geometry.viewport", "geometry.location"],
            types: ['geocode'],
        });
        const updateAutocompleteBounds = () => {
            const bounds = map.getBounds();
            autocompleteService.setBounds(bounds);
        };
        const onPlaceChanged = () => {
            const place = autocompleteService.getPlace();
            if (place.formatted_address && place.geometry?.viewport && place.geometry.location) {
                const isEligibleLocation = validateLocation(place.geometry.location);
                if (!isEligibleLocation) {
                    setIsInvalidLocation(true);
                    setLocationErrorMessage(`Location isn't available for service or within service range, choose another.`);
                    setLocationInput('');
                    return;
                }
                map.fitBounds(place.geometry.viewport);
                setCenterCoords(place.geometry.location);
                setLocationInput(place.formatted_address);
            }
        };
        autocompleteService.addListener('place_changed', onPlaceChanged);
        map.addListener('bounds_changed', updateAutocompleteBounds);
        // Cleanup function to remove the listeners
        return () => {
            window.google.maps.event.clearInstanceListeners(autocompleteService);
            window.google.maps.event.clearInstanceListeners(map);
        };
    }, [placesLib, map]);
    const handleSubmit = useCallback(() => {
        if (!simulationRequest) {
            throw new Error(`No simulation request found`);
        }
        // Check: if missing start/stop times, flag error in the DateTimePicker
        if (!locationInput) {
            setIsInvalidLocation(true);
            setLocationErrorMessage(`Missing city/location`);
            return;
        }
        if (!globalStartTime) {
            setIsInvalidGlobalStartTime(true);
            return;
        }
        if (!globalEndTime) {
            setIsInvalidGlobalEndTime(true);
            return;
        }
        if (!snowDepthInput || snowDepthInput <= 0) {
            setIsInvalidSnowDepth(true);
            return;
        }
        const model = simulationRequest.optimizationRequest.model;
        dispatch(setRequest({
            optimizationRequest: {
                ...simulationRequest.optimizationRequest,
                model: {
                    ...model,
                    globalStartTime: globalStartTime.toISOString(),
                    globalEndTime: globalEndTime.toISOString(),
                }
            }
        }));
        if (!centerCoords || !locationInput)
            return;
        dispatch(setReferenceLocation({
            streetAddress: locationInput,
            coords: {
                lat: centerCoords.lat(),
                lng: centerCoords.lng(),
            }
        }));
        // Handle snow depth
        dispatch(setLengthUnit(snowDepthUnitInput));
        if (snowDepthUnitInput === 'in') {
            setDistanceUnit('mi');
            setTemperatureUnit('F');
            const snowDepthInCm = parseFloat((snowDepthInput * 2.54).toFixed(2));
            dispatch(setSnowDepthTrigger(snowDepthInCm));
        }
        else {
            dispatch(setSnowDepthTrigger(snowDepthInput));
        }
        ;
        handleNext();
    }, [dispatch, centerCoords, handleNext, locationInput, simulationRequest, globalEndTime, globalStartTime, snowDepthInput, snowDepthUnitInput]);
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(Grid, { item: true, xs: isDesktop ? 9 : 12 },
            React.createElement(Stack, { spacing: 1.5 },
                React.createElement(TextField, { autoComplete: "off", error: isInvalidLocation, helperText: isInvalidLocation ? locationErrorMessage : null, fullWidth: true, inputRef: textFieldRef, value: locationInput, onChange: (e) => {
                        handleTextFieldChange(e.target.value);
                        setIsInvalidLocation(false);
                        setLocationErrorMessage('');
                    }, type: "text", placeholder: 'Enter city or location', sx: { height: '40px', paddingBottom: '30px' } }),
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(DateTimePicker
                    // Date pickers render in local timezone, read/write in UTC
                    , { 
                        // Date pickers render in local timezone, read/write in UTC
                        slotProps: {
                            textField: {
                                error: isInvalidGlobalStartTime,
                                helperText: isInvalidGlobalStartTime ? 'Missing/invalid global start time' : null,
                            },
                        }, label: "Enter general start time", value: globalStartTime, onChange: (e) => {
                            setGlobalStartTime(e);
                            setIsInvalidGlobalStartTime(false);
                        }, minDateTime: EARLIEST_SIMULATION_START_DATE, maxDateTime: LATEST_SIMULATION_START_DATE })),
                globalStartTime && (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(DateTimePicker, { slotProps: {
                            textField: {
                                error: isInvalidGlobalEndTime,
                                helperText: isInvalidGlobalEndTime ? 'Missing/invalid global end time' : null,
                            },
                        }, label: "Enter general end time", value: globalEndTime, onChange: (e) => {
                            if (e && e.isValid()) {
                                // TODO: Keep it as onChange vs. onAccept until MM calls limit is handled
                                setGlobalEndTime(e);
                                setIsInvalidGlobalEndTime(false);
                            }
                        }, minDateTime: globalStartTime.add(1, 'day'), maxDateTime: globalStartTime.add(5, 'day') }))),
                globalEndTime && (React.createElement(Grid, { container: true, direction: 'row', spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 5, style: { paddingLeft: '0px' } },
                        React.createElement(TextField, { label: 'Snow depth to trigger removal', error: isInvalidSnowDepth, helperText: isInvalidSnowDepth ? 'Snow depth must be a number > 0' : null, fullWidth: true, value: snowDepthInput, onChange: (e) => {
                                setSnowDepthInput(parseFloat(e.target.value));
                                setIsInvalidSnowDepth(false);
                            }, type: "number", placeholder: 'Enter required snow depth to dispatch a removal, e.g. 5 cm', sx: { height: '40px', paddingBottom: '30px' } })),
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement(Select, { label: "Choose in/cm", defaultValue: snowDepthUnitInput, onChange: (e) => {
                                const newUnit = e.target.value;
                                if (snowDepthUnitInput === 'cm' && newUnit === 'in') {
                                    const adjustedSnowDepth = (Math.round((snowDepthInput * 0.39) * 2) / 2).toFixed(1);
                                    setSnowDepthInput(parseFloat(adjustedSnowDepth));
                                }
                                else if (snowDepthUnitInput === 'in' && newUnit === 'cm') {
                                    const adjustedSnowDepth = (Math.round((snowDepthInput * 2.54) * 2) / 2).toFixed(1);
                                    setSnowDepthInput(parseFloat(adjustedSnowDepth));
                                }
                                setSnowDepthUnitInput(e.target.value);
                            } },
                            React.createElement(MenuItem, { value: 'cm' }, "cm"),
                            React.createElement(MenuItem, { value: 'in' }, "in"))))),
                shouldDisplayGraph && (React.createElement(WeatherGraphProvider, { center: centerCoords },
                    React.createElement(WeatherGraph, null))))),
        React.createElement(Grid, { item: true, xs: 8 },
            React.createElement(Stack, { direction: "row", spacing: 2, justifyContent: "center" },
                React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Submit"),
                React.createElement(Button, { onClick: handleBack, color: "secondary" }, "Back")))));
}
;
