import React, { useState, useCallback, useEffect, useRef } from "react";
import { TextField, IconButton, Button, Box, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.js';
import Grid from '@mui/material/Grid/Grid.js';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import { AddIcon, EditIcon, CheckIcon, DeleteIcon } from '../constants/formIcons.js';
import { useFormContext } from "./VehicleFormProvider.js";
import { useAppSelector } from "../redux/hooks.js";
dayjs.extend(utc);
dayjs.extend(timezone);
function FormRow({ row }) {
    // Flow:
    // 1. Load values from row props into state variables.
    // 2. update state variables with input.
    // 3. once isDone, then either update formContext.rows or update visits directly with updated shipment
    if (!row.value.label ||
        !row.value.startLocation ||
        !row.value.startTimeWindows ||
        !row.value.endTimeWindows) {
        throw new Error(`Vehicle missing label, start location or start/end time windows!`);
    }
    const formContext = useFormContext();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeFormatType = useAppSelector((state) => state.user.timeFormat);
    // 1. Reading Row prop and updating state variables
    // Initializes start and end time windows with global params
    const globalStartTime = dayjs(row.value.startTimeWindows[0].startTime);
    const globalEndTime = dayjs(row.value.startTimeWindows[0].endTime);
    function getVehicleLabelFromProps() {
        try {
            const serializedLabel = row.value.label.split('///');
            const deserializedVehicleLabel = decodeURIComponent(serializedLabel[0]);
            return deserializedVehicleLabel ? deserializedVehicleLabel : '';
        }
        catch (e) {
            throw new Error(`Was not able to parse or deserialize vehicle label from label: ${row.value.label}`);
        }
        ;
    }
    ;
    function getStartLocationAddressFromProps() {
        try {
            const serializedLabel = row.value.label.split('///');
            const savedLocationAddress = serializedLabel[1];
            return savedLocationAddress ? savedLocationAddress : '';
        }
        catch (e) {
            throw new Error(`Was not able to parse origin address from label: ${row.value.label}`);
        }
        ;
    }
    ;
    const startLocationRef = useRef(null);
    const [vehicleStartTime, setVehicleStartTime] = useState(globalStartTime);
    const [vehicleEndTime, setVehicleEndTime] = useState(globalEndTime);
    const [vehicleLabel, setVehicleLabel] = useState(getVehicleLabelFromProps());
    const [startLocationAddress, setStartLocationAddress] = useState(getStartLocationAddressFromProps());
    const [startLocationCoords, setStartLocationCoords] = useState(row.value.startLocation);
    const [isLabelValueError, setIsLabelValueError] = useState(false);
    const [isInvalidVehicleLocation, setIsInvalidVehicleLocation] = useState(false);
    const [isInvalidGlobalStartTime, setIsInvalidGlobalStartTime] = useState(false);
    const [isInvalidGlobalEndTime, setIsInvalidGlobalEndTime] = useState(false);
    // 2. Update state variables with user input
    const handleVehicleLabelChange = useCallback((e) => {
        const labelValue = e.target.value;
        if (labelValue.length >= 4 && labelValue.length <= 63) {
            setVehicleLabel(labelValue);
            setIsLabelValueError(false);
        }
        else {
            setVehicleLabel(labelValue);
            setIsLabelValueError(true);
        }
    }, []);
    const handleStartLocationAddress = useCallback((locationAddress) => {
        setStartLocationAddress(locationAddress);
        if (startLocationRef.current) {
            const getAutocompleteAddress = function (formattedAddress, locationCoords) {
                setStartLocationAddress(formattedAddress);
                setStartLocationCoords({
                    latitude: locationCoords.lat(),
                    longitude: locationCoords.lng(),
                });
            };
            formContext.additionalContext.fetchAddressSuggestions(startLocationRef, getAutocompleteAddress);
        }
    }, [formContext.additionalContext]);
    // 3. Update Rows with changed row
    const handleRowDelete = useCallback(() => {
        formContext.deleteRow(row.id);
    }, [formContext, row.id]);
    const handleRowUpdate = useCallback(() => {
        if (isLabelValueError) {
            return;
            // throw new Error(`Require valid label on vehicle for submission`);
        }
        if (!startLocationAddress || !startLocationCoords.latitude || !startLocationCoords.longitude) {
            setIsInvalidVehicleLocation(true);
            return;
            // throw new Error(`Missing valid location details from submission!`);
        }
        const startTimeWindow = {
            startTime: vehicleStartTime.toISOString(),
            endTime: vehicleEndTime.toISOString(),
        };
        const endTimeWindow = {
            startTime: vehicleStartTime.toISOString(),
            endTime: vehicleEndTime.toISOString(),
        };
        const serializedVehicleLabel = encodeURIComponent(vehicleLabel);
        const serializedLabel = `${serializedVehicleLabel}///${startLocationAddress}`;
        const updatedVehicle = {
            label: serializedLabel,
            startLocation: startLocationCoords,
            startTimeWindows: [startTimeWindow],
            endTimeWindows: [endTimeWindow],
        };
        const updatedRow = {
            id: row.id,
            value: updatedVehicle,
            isDone: !row.isDone,
        };
        formContext.updateRow(updatedRow);
    }, [formContext, row.id, row.isDone, isLabelValueError, startLocationAddress, startLocationCoords, vehicleLabel, vehicleStartTime, vehicleEndTime]);
    return (React.createElement(Box, { key: row.id, sx: { flexGrow: 1, mb: 2 } },
        React.createElement(Grid, { container: true, spacing: 3, alignItems: "center" },
            row.isDone ? (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12, md: 9 },
                    React.createElement(Typography, { variant: "h6" }, vehicleLabel)),
                React.createElement(Grid, { item: true, xs: 12, md: 9 },
                    React.createElement(Typography, { variant: "body1" },
                        "Starts at ",
                        vehicleStartTime?.tz(tz).format(timeFormatType)),
                    React.createElement(Typography, { variant: "body1" },
                        "Ends at ",
                        vehicleEndTime?.tz(tz).format(timeFormatType)),
                    React.createElement(Typography, { variant: "body1" },
                        "Starts/ends from ",
                        startLocationAddress)))) : (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12, md: 9 },
                    React.createElement(TextField, { autoComplete: "off", key: row.id, value: vehicleLabel, onChange: handleVehicleLabelChange, disabled: row.isDone, type: "text", placeholder: 'Enter name of vehicle (e.g. F-150, AB 12345)', fullWidth: true, style: { height: '75px', boxSizing: 'border-box' }, error: isLabelValueError, helperText: isLabelValueError ? 'Label must be 4-63 characters long' : null })),
                React.createElement(Grid, { item: true, xs: 12, md: 9 },
                    React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { item: true, xs: 6 },
                                React.createElement(DateTimePicker
                                // Date pickers render in local timezone, read/write in UTC
                                , { 
                                    // Date pickers render in local timezone, read/write in UTC
                                    slotProps: {
                                        textField: {
                                            error: isInvalidGlobalStartTime,
                                            helperText: isInvalidGlobalStartTime ? 'Missing/invalid global start time' : null,
                                        },
                                    }, label: "Choose start time for the vehicle", value: vehicleStartTime, onChange: (e) => {
                                        setVehicleStartTime(e);
                                        setIsInvalidGlobalStartTime(false);
                                    }, minDateTime: globalStartTime, maxDateTime: globalEndTime })),
                            React.createElement(Grid, { item: true, xs: 6 },
                                React.createElement(DateTimePicker, { slotProps: {
                                        textField: {
                                            error: isInvalidGlobalEndTime,
                                            helperText: isInvalidGlobalEndTime ? 'Missing/invalid global end time' : null,
                                        },
                                    }, label: "Choose end time for the job", value: vehicleEndTime, onChange: (e) => {
                                        setVehicleEndTime(e);
                                        setIsInvalidGlobalEndTime(false);
                                    }, minDateTime: globalStartTime, maxDateTime: globalEndTime }))))),
                React.createElement(Grid, { item: true, xs: 12, md: 9 },
                    React.createElement(TextField, { fullWidth: true, inputRef: startLocationRef, value: startLocationAddress, onChange: (e) => {
                            handleStartLocationAddress(e.target.value);
                            setIsInvalidVehicleLocation(false);
                        }, disabled: row.isDone, type: "text", placeholder: 'Where does the vehicle start/end? (e.g. depot)', style: { height: '60px' }, error: isInvalidVehicleLocation, helperText: isInvalidVehicleLocation ? 'Missing vehicle start/return location' : null })))),
            React.createElement(Grid, { item: true, xs: 12, md: 3, style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } },
                React.createElement(IconButton, { onClick: handleRowUpdate, color: row.isDone ? 'primary' : 'success' }, row.isDone ?
                    React.createElement(EditIcon, null) :
                    React.createElement(CheckIcon, null)),
                React.createElement(IconButton, { onClick: handleRowDelete },
                    React.createElement(DeleteIcon, null))))));
}
;
export default function VehicleForm({ handleNext, handleBack }) {
    const formContext = useFormContext();
    const [showPendingRowsError, setShowPendingRowsError] = useState(false);
    const handleAddRow = () => {
        formContext.addRow();
    };
    useEffect(() => {
        const pendingRows = formContext.rows.filter(row => !row.isDone);
        if (pendingRows.length) {
            // TODO: user might be expecting error to disappear on updating FormRow,
            // not when they hit submit button.
            setShowPendingRowsError(true);
        }
        else {
            setShowPendingRowsError(false);
        }
    }, [formContext.rows]);
    const handleSubmit = () => {
        // Check 1: check for incomplete rows
        // Check 2: check for unsubmitted rows
        if (showPendingRowsError) {
            return;
        }
        const vehicles = formContext.rows
            .filter(row => row.isDone)
            .map(row => row.value);
        formContext.additionalContext.setVehicles(vehicles);
        handleNext();
        console.log(JSON.stringify(vehicles, null, 2));
    };
    return (React.createElement("div", { className: "form", style: { width: '100%' } },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Stack, { spacing: 2, sx: { width: '100%' } }, formContext.rows.map((row) => (React.createElement(FormRow, { key: row.id, row: row })))),
            showPendingRowsError ?
                React.createElement(Typography, { variant: "body1", color: 'red' }, "Finish pending vehicle \u2705 or remove it \u26D4\uFE0E") :
                null,
            React.createElement(Stack, { direction: "row", spacing: 2, sx: { mt: 2, justifyContent: 'center' } },
                React.createElement(Button, { onClick: handleAddRow },
                    React.createElement(AddIcon, { color: "primary" })),
                React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Submit"),
                React.createElement(Button, { onClick: handleBack, color: "secondary" }, "Back")))));
}
;
