import React, { useEffect, useState } from "react";
import { getFirestore, collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import moment from 'moment';
import { CalendarIcon, SparklesIcon, CheckCircleIcon, LockIcon, EditIcon } from "../constants/formIcons.js";
import AuthForm from "./AuthForm.js";
import { useAuthContext } from "./AuthProvider.js";
import { SIMULATION_STATUS } from "../constants/simulationStatus.js";
import { baseURL } from "../constants/routes.js";
import DeviceDetect from "./DeviceDetect.js";
import fetchSimulationRequest from "../resources/fetchSimulationRequest.js";
import { useAppDispatch } from "../redux/hooks.js";
import { setReferenceLocation, setRequest, setSnowDepthTrigger } from "../redux/simulationSlice.js";
import { setDistanceUnit, setLengthUnit, setTemperatureUnit } from "../redux/userSlice.js";
const PUBLIC_USER_ID = 'public';
function StepperSimulations({ handleNext, simulations }) {
    const authContext = useAuthContext();
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    const SQUARE_IMG_RESOLUTION_IN_PX = isDesktop ? '120' : '90';
    const dispatch = useAppDispatch();
    if (!simulations.length)
        return null;
    function fetchRandomImgUrl(seed) {
        return `https://picsum.photos/seed/${seed}/${SQUARE_IMG_RESOLUTION_IN_PX}/${SQUARE_IMG_RESOLUTION_IN_PX}`;
    }
    ;
    function formatCreatedAt(created_at) {
        if (typeof created_at === 'string') {
            throw new Error(`Expected created_at to be Timestamp`);
        }
        const date = created_at.toDate();
        return moment(date).format('MMM DD');
    }
    ;
    function handleSimulationEdit(simulation) {
        if (!simulation.solutionUrl) {
            console.error(`Missing solutionUrl`);
            return;
        }
        ;
        fetchSimulationRequest(simulation.solutionUrl, authContext.currentUser['uid'], authContext.idToken)
            .then(simulationRequest => {
            dispatch(setRequest(simulationRequest));
            dispatch(setSnowDepthTrigger(simulation.snowDepthTrigger));
            if (simulation.referenceLocation) {
                const referenceLocation = simulation.referenceLocation.split('///');
                const referenceLocationCoords = referenceLocation[1].split(',');
                dispatch(setReferenceLocation({
                    streetAddress: referenceLocation[0],
                    coords: {
                        lat: parseFloat(referenceLocationCoords[0]),
                        lng: parseFloat(referenceLocationCoords[1])
                    }
                }));
            }
            if (simulation.lengthUnit) {
                // TODO: Hack for setting metric until we ask user
                if (simulation.lengthUnit === 'cm') {
                    dispatch(setDistanceUnit('km'));
                    dispatch(setTemperatureUnit('C'));
                }
                else {
                    dispatch(setDistanceUnit('mi'));
                    dispatch(setTemperatureUnit('F'));
                }
                ;
                dispatch(setLengthUnit(simulation.lengthUnit));
            }
        })
            .catch(e => console.error(`Issues setting request captured from solutionUrl ${simulation.solutionUrl}`));
        handleNext();
    }
    ;
    return (React.createElement(Grid, { container: true, spacing: 2 }, simulations.map((simulation, idx) => (React.createElement(Grid, { item: true, xs: isDesktop ? 4 : 6, key: simulation.simulationId },
        React.createElement(Box, { sx: {
                border: '1px solid blue',
                borderRadius: '4px',
                p: 1
            }, onClick: () => window.open(`${baseURL}/simulation/${simulation.simulationId}`, '_blank', 'noopener,noreferrer') },
            React.createElement(Stack, { direction: 'column', alignItems: 'center' },
                React.createElement("img", { src: simulation.imgUrl || fetchRandomImgUrl(simulation.simulationId), alt: "Thumbnail", style: {
                        maxWidth: `${SQUARE_IMG_RESOLUTION_IN_PX}px`,
                        maxHeight: `${SQUARE_IMG_RESOLUTION_IN_PX}px`
                    } }),
                React.createElement(Typography, { variant: "body1", display: "block", textAlign: 'center', gutterBottom: true }, simulation.title || `Simulation #${idx + 1}`),
                React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, formatCreatedAt(simulation.created_at)))),
        React.createElement(IconButton, { onClick: () => handleSimulationEdit(simulation), sx: { 'color': 'blue' } },
            React.createElement(EditIcon, null)))))));
}
;
export default function StepperLandingStep({ handleNext }) {
    const authContext = useAuthContext();
    const [selection, setSelection] = useState(0);
    const [publicSimulations, setPublicSimulations] = useState([]);
    const [userSimulations, setUserSimulations] = useState([]);
    useEffect(() => {
        if (!authContext.currentUser) {
            return;
        }
        const db = getFirestore(authContext.app);
        const collectionRef = collection(db, 'results');
        const userIds = [PUBLIC_USER_ID];
        if (authContext.currentUser?.isAnonymous === false) {
            if (!authContext.currentUser?.uid) {
                throw new Error(`Non-anonyomus user does not have user credentials: missing uid`);
            }
            userIds.push(authContext.currentUser.uid);
        }
        const q = query(collectionRef, where('userId', 'in', userIds), where('status', 'in', [SIMULATION_STATUS.requestSuccessful]), orderBy('created_at', 'asc'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                const publicResults = [];
                const userResults = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.userId === PUBLIC_USER_ID) {
                        publicResults.push(data);
                    }
                    else {
                        userResults.push(data);
                    }
                });
                setPublicSimulations(publicResults);
                setUserSimulations(userResults);
                unsubscribe();
            }
        });
        return () => unsubscribe();
    }, [authContext.app, authContext.currentUser]);
    return (React.createElement(Stack, { direction: "column", spacing: 2, sx: { mt: 2, justifyContent: 'center' } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center', gap: 2 } },
            React.createElement(Box, { sx: {
                    border: selection === 1 ? '2px solid blue' : 'none',
                    borderRadius: '4px',
                    p: 1
                } },
                React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, "Custom"),
                React.createElement(IconButton, { onClick: () => setSelection(1), color: selection === 1 ? 'primary' : 'default' },
                    React.createElement("div", { style: { width: 50, height: 50 } },
                        React.createElement(CalendarIcon, null)),
                    selection === 1 ? (React.createElement(CheckCircleIcon, { isSelected: true })) : (React.createElement(CheckCircleIcon, { isSelected: false })))),
            React.createElement(Box, { sx: {
                    border: selection === 2 ? '2px solid blue' : 'none',
                    borderRadius: '4px',
                    p: 1
                } },
                React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, "Curated"),
                React.createElement(IconButton, { onClick: () => setSelection(2), color: selection === 2 ? 'primary' : 'default' },
                    React.createElement("div", { style: { width: 50, height: 50 } },
                        React.createElement(SparklesIcon, null)),
                    selection === 2 ? (React.createElement(CheckCircleIcon, { isSelected: true })) : (React.createElement(CheckCircleIcon, { isSelected: false })))),
            userSimulations.length > 0 &&
                React.createElement(Box, { sx: {
                        border: selection === 3 ? '2px solid blue' : 'none',
                        borderRadius: '4px',
                        p: 1
                    } },
                    React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, "Private"),
                    React.createElement(IconButton, { onClick: () => setSelection(3), color: selection === 3 ? 'primary' : 'default' },
                        React.createElement("div", { style: { width: 50, height: 50 } },
                            React.createElement(LockIcon, null)),
                        selection === 3 ? (React.createElement(CheckCircleIcon, { isSelected: true })) : (React.createElement(CheckCircleIcon, { isSelected: false }))))),
        selection === 2 && (React.createElement(React.Fragment, null,
            React.createElement(StepperSimulations, { handleNext: handleNext, simulations: publicSimulations }))),
        selection === 3 && (React.createElement(React.Fragment, null,
            React.createElement(StepperSimulations, { handleNext: handleNext, simulations: userSimulations }))),
        React.createElement(Divider, null),
        React.createElement(AuthForm, null),
        selection === 1 &&
            React.createElement(Button, { onClick: handleNext, color: "primary", variant: "contained", sx: { mt: 2, justifyContent: 'center' } }, "Continue")));
}
;
